import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { PageScrollService } from 'ngx-page-scroll-core';

import * as moment from 'moment-timezone';

import { ApiService } from 'projects/microsite/src/app/services/api.service';
import { LoginService } from 'projects/microsite/src/app/services/login.service';
import { UiService } from 'projects/microsite/src/app/services/ui.service';
import { VALIDATOR_PATTERN_NAME, VALIDATOR_PATTERN_ADDRESS, VALIDATOR_PATTERN_ZIP, VALIDATOR_PATTERN_MOBILE, VALIDATOR_PATTERN_EMAIL, VALIDATOR_PATTERN_MIN_ONE_LETTER, VALIDATOR_STATE } from 'projects/microsite/src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormhelperService } from 'projects/sb-lib/src/lib/modules/forms/services/formhelper.service';
import { CollectEventdataComponent } from '../../../partials/collect-eventdata/collect-eventdata.component';
import { CandidateModel } from 'projects/sb-lib/src/lib/models/candidate.model';
import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { faTrash, faUserCircle } from '@fortawesome/pro-light-svg-icons';

declare var UIkit: any;

const MAXYEARS = 60;

@Component({
	selector: 'app-myprofile',
	templateUrl: './myprofile.component.html',
	styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

	candidate: CandidateModel = null;
	profile: any = null;
	loadingMode = 'loading';
	avatarUrl: string = null;
	editMode: string = null;
	avatarMode = false;
  deleteProfileError = false;

	dataForm: UntypedFormGroup;
	careerEntries: UntypedFormArray;

	// min/max for date pickers
	min: any = null;
	max: any = null;

	pagemode: string = null;
	toComplete: Array<string> = [];

	// image upload
	public uploader: FileUploader = null;
	public hasBaseDropZoneOver: boolean = false;
	public hasAnotherDropZoneOver: boolean = false;

  faUserCircle = faUserCircle;
  faTrash = faTrash;

  @ViewChild('datacollector') datacollector: CollectEventdataComponent;

	constructor(
		private api: ApiService,
		private loginService: LoginService,
		private ui: UiService,
		private fb: UntypedFormBuilder,
		private fh: FormhelperService,
    private router: Router,
		private route: ActivatedRoute,
		private pageScrollService: PageScrollService,
		@Inject(DOCUMENT) private document: any,
		public transloco: TranslocoService
	) { }

	ngOnInit() {
		this.max = moment();
		this.min = moment().subtract(MAXYEARS, 'years');

		// set uploader
		const auth = this.loginService.getAuthData();
		this.uploader = new FileUploader({
			url: this.api.getAvatarImageUploadUrl(),
			authToken: 'Bearer ' + auth.token,
			disableMultipart: false
		});
		this.uploader.onBeforeUploadItem = (item) => {
			item.withCredentials = false;
		};
		this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			if (status === 200) {
				// this.candidate.avatar_image = response;
				this.clearAvatarMode(false);
			} else {
				// @todo: ERROR
			}
		};

		// build form
		const career: UntypedFormArray = this.fb.array([]);
		this.dataForm = this.fb.group({
			personal: this.fb.group({
				firstname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
				middlename: new UntypedFormControl(''),
				lastname: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_NAME)]),
				address_street: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_ADDRESS)]),
				address_zip: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_ZIP)]),
				address_city: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_ADDRESS)]),
				address_state: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_STATE)]),
				mobile: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MOBILE)]),
				email: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
			}),
			nocareer: new UntypedFormControl(''),
      knbefore: new UntypedFormControl(''),
			career,
		});
		this.loadProfile();
	}

	createCareerEntry(data: any = null): UntypedFormGroup {
		const newGroup = this.fb.group({
			employer: new UntypedFormControl((data !== null) ? data.employer : '', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MIN_ONE_LETTER)]),
			from: new UntypedFormControl((data !== null) ? moment(data.from).toDate() : ''),
			to: new UntypedFormControl((data !== null) ? moment(data.to).toDate() : ''),
			occupation: new UntypedFormControl((data !== null) ? data.occupation : '', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MIN_ONE_LETTER)])
		});
		newGroup.get('from').setValidators([Validators.required, (control: AbstractControl): any => {
      const from = moment(control.value);
      const to = moment(control.parent.get('to').value);
			if (from.isAfter(to)) {
				return { fromafterto: true };
			}
			return null;
		}]);
		newGroup.get('to').setValidators([Validators.required, (control: AbstractControl): any => {
      const to = moment(control.value);
      const from = moment(control.parent.get('from').value);
			if (to.isBefore(from)) {
				return { tobeforefrom: true };
			}
			return null;
		}]);
		return newGroup;
	}

	addCareerEntry() {
		this.careerEntries = this.dataForm.get('career') as UntypedFormArray;
		if (this.careerEntries.length < 3) {
			this.careerEntries.push(this.createCareerEntry());
		}
	}

	deleteCareerEntry(index: number) {
		this.careerEntries = this.dataForm.get('career') as UntypedFormArray;
		if (this.careerEntries.length > 0) {
			this.careerEntries.removeAt(index);
		}
	}

	loadProfile() {
		this.loadingMode = 'loading';
		this.api.getProfile().subscribe(
			data => {
				this.setData(data.candidate, data.candidateevents);
				this.loadingMode = null;
				this.route
					.queryParams
					.subscribe(params => {
						this.pagemode = params['mode'];
						this.setCompleteMode();
					});
			},
			error => {
				this.loadingMode = 'error';
			}
		);
	}

	sendProfile() {
		let message = '';
		switch (this.editMode) {
			case ('personal'): {
				message = this.transloco.translate('profile.myprofile.personal_information_saved');
				break;
			}
			case ('career'): {
				message = this.transloco.translate('profile.myprofile.career_data_saved');
				break;
			}
		}
		this.api.postProfile(this.candidate).subscribe(
			(candidate: any) => {
				this.setData(candidate);
				this.ui.addMessage(message, 'success', this.editMode);
				this.editMode = null;
			},
			error => {
				if (error.common.length > 0) {
					this.ui.addMessage(error.common.join('<br>'), 'error', this.editMode);
				}
			}
		);
	}

	setData(candidate: any, candidateevents: Array<any> = null) {
		this.candidate = new CandidateModel().deserialize(candidate);
		if (this.candidate !== null) {
			this.avatarUrl = candidate.avatar_image;
			this.profile = this.candidate.profile_data;
			// personal
			this.dataForm.get('personal').patchValue({
				firstname: this.candidate.firstname,
				middlename: this.candidate.middlename,
				lastname: this.candidate.lastname,
				address_street: this.candidate.address_street,
				address_zip: this.candidate.address_zip,
				address_city: this.candidate.address_city,
        address_state: this.candidate.address_state,
				mobile: this.fh.phoneToValue(this.candidate.mobile, false),
				email: (this.candidate.hasTempEmail()) ? '' : this.candidate.email,
			});
			if (this.profile !== null) {
				// career
				if (this.profile.career) {
					this.clearCareerData();
					const career = this.profile['career'];
					if (career !== null) {
						if (career.length > 0) {
							career.forEach((entry: any) => {
								entry.from = moment(entry.from).toDate();
								entry.to = moment(entry.to).toDate();
								this.careerEntries.push(this.createCareerEntry(entry));
							});
							this.dataForm.patchValue({
								nocareer: false
							});
						} else {
							this.dataForm.patchValue({
								nocareer: true
							});
						}
					} else {
						this.dataForm.patchValue({
							nocareer: false
						});
					}
          this.dataForm.patchValue({knbefore: !!this.profile.knbefore});
				}
			}
      if (this.candidate.hasTempEmail()) {
        if (Array.isArray(candidateevents) && candidateevents.length > 0) {
          this.datacollector.showModal(new CandidateEventModel().deserialize(candidateevents[0]));
        }
      }
		}
	}

	editPersonalData() {
    if (this.candidate?.can_edit_profile === true) {
		  this.editMode = 'personal';
    }
	}

	savePersonalData() {
    if (this.candidate?.can_edit_profile === true) {
      this.candidate.firstname = this.dataForm.get('personal.firstname').value;
      this.candidate.middlename = this.dataForm.get('personal.middlename').value;
      this.candidate.lastname = this.dataForm.get('personal.lastname').value;
      this.candidate.address_street = this.dataForm.get('personal.address_street').value;
      this.candidate.address_zip = this.dataForm.get('personal.address_zip').value;
      this.candidate.address_city = this.dataForm.get('personal.address_city').value;
      this.candidate.address_state = this.dataForm.get('personal.address_state').value;
      this.candidate.mobile = this.fh.phoneToValue(this.dataForm.get('personal.mobile').value);
      this.candidate.email = this.dataForm.get('personal.email').value;
    }
		this.sendProfile();
		this.setCompleteMode();
	}

	editCareerData() {
		this.editMode = 'career';
		if (this.dataForm.get('career').value.length === 0 && this.dataForm.get('nocareer').value !== true) {
			this.addCareerEntry();
		}
	}

	clearCareerData() {
		this.careerEntries = this.dataForm.get('career') as UntypedFormArray;
		while (this.careerEntries.length !== 0) {
			this.careerEntries.removeAt(0);
		}
	}

	saveCareerData() {
		if (this.dataForm.get('nocareer').value === true) {
			this.candidate.profile_data['career'] = [];
		} else {
			const career = this.dataForm.get('career').value;
			career.forEach((entry: any) => {
				entry.from = moment(entry.from).format('YYYY-MM-DD');
				entry.to = moment(entry.to).format('YYYY-MM-DD');
			});
			this.candidate.profile_data['career'] = career;
		}
    this.candidate.profile_data['knbefore'] = this.dataForm.get('knbefore').value;
		this.sendProfile();
		this.setCompleteMode();
	}

	onNoCareerChanged(value: boolean) {
		if (value === true) {
			this.clearCareerData();
			this.dataForm.get('knbefore').setValue(false);
		} else {
			this.addCareerEntry();
		}
	}

	onKnBeforeChanged(value: boolean) {
		if (value === true) {
			this.clearCareerData();
			this.dataForm.get('nocareer').setValue(false);
			this.dataForm.get('nocareer').disable();
			this.addCareerEntry();
		} else {
			this.dataForm.get('nocareer').enable();
		}
	}

	cancelForm() {
		this.setData(this.candidate);
		this.editMode = null;
	}

	setCompleteMode() {
		if (this.pagemode === 'complete') {
			this.toComplete = this.candidate.getNeededFieldList();
			setTimeout(() => {
				this.pageScrollService.scroll({
					document: this.document,
					scrollTarget: '.show-complete-info',
				});
			}, 500);
		}
	}

  onRequestDeleteProfile(): void {
    this.deleteProfileError = false;
    UIkit.modal('#deleteModal', {}).show();
  }

  onDeleteProfile(): void {
    this.ui.resetMessage();
    this.api.deleteProfile().subscribe(() => {
      this.loginService.setLoggedOut();
      this.router.navigate(['/']);
    }, (error: any) => {
      this.deleteProfileError = true;
      this.ui.addMessage(this.transloco.translate('profile.myprofile.delete_errors.' + error.fields?.message), 'error', 'delete_profile');
    }).add(() => {
      UIkit.modal('#deleteModal', {}).hide();
    });
  }

  onCollected(data: any): void {
    this.setData(data.candidate);
  }

	// file handling

	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	fileOverAnother(e: any): void {
		this.hasAnotherDropZoneOver = e;
	}

	setAvatarMode() {
		this.avatarMode = true;
	}

	clearAvatarMode(reset: boolean = true) {
		this.avatarMode = false;
		this.uploader.queue = [];
		if (reset === true) {
			this.avatarUrl = this.candidate.avatar_image;
		}
	}

	onSelectAvatarImageFile(event: any) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (readerEvent: any) => {
				this.avatarUrl = readerEvent.target['result'];
			}
		}
	}

	uploadAvatarImage() {
		this.uploader.queue = [this.uploader.queue.pop()];
		this.uploader.uploadAll();
	}

}
