import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

import { ApiService } from '../../../../src/app/services/api.service';
import { FormhelperService } from '../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';

import { CandidateEventModel } from '../../../../../sb-lib/src/lib/models/candidateevent.model';
import { JobtypeModel } from '../../../../../sb-lib/src/lib/models/jobtype.model';
import { JoblocationModel } from '../../../../../sb-lib/src/lib/models/joblocation.model';
import { JobModel } from '../../../../../sb-lib/src/lib/models/job.model';
import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

@Component({
	selector: 'app-jobselector',
	templateUrl: './jobselector.component.html',
	styleUrls: ['./jobselector.component.scss']
})
export class JobselectorComponent implements OnInit {

	loading: boolean = null;
	jobData: any = null;

	jobtypes: JobtypeModel[] = [];
	joblocations: JoblocationModel[] = [];
	startdates: JobModel[] = [];
	filteredJobLocations: JoblocationModel[] = [];
	filteredStartDates: JobModel[] = [];
	activeJobtype: JobtypeModel = null;
	activeJoblocation: JoblocationModel = null;
	activeStartdate: JobModel = null;
  hasCitizenship = false;
  countries: Array<any> = [];

	jobForm: UntypedFormGroup = new UntypedFormGroup({
		jobtype: new UntypedFormControl('', Validators.required),
		joblocation: new UntypedFormControl('', Validators.required),
		date_start: new UntypedFormControl('', Validators.required),
		citizenship: new UntypedFormControl(''),
	});

	constructor(
		private api: ApiService,
		private fh: FormhelperService,
    public transloco: TranslocoService
  ) { }

	@Input() mode = 'my-event';
	@Input() event: EventModel = null;
	@Input() candidateevent: CandidateEventModel = null;
	@Input() showValidation = false;
	@Output() set = new EventEmitter<any>();
	@Output() cancelled = new EventEmitter<any>();
	@Output() changed = new EventEmitter<any>();

	ngOnInit() {
		this.loading = true;
		this.api.getJobdata(this.event.id).subscribe(result => {
			this.jobData = result;
			this.loading = false;
			// set form data
      this.countries = this.jobData.countries?.map((entry: any) => {
        return {
          id: entry.code,
          select_value: entry.name
        };
      }).reduce((acc: any, element: any) => {
        if (element.id === 'GB') {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []) || [];
			if (this.jobData.jobtypes !== null) {
				this.jobData.jobtypes.forEach((jobtype: JobtypeModel) => {
					jobtype.select_value = jobtype.title;
					this.jobtypes.push(jobtype);
				});
			}
			if (this.jobData.joblocations !== null) {
				this.jobData.joblocations.forEach((joblocation: JoblocationModel) => {
					joblocation.select_value = joblocation.address_city;
					this.joblocations.push(joblocation);
				});
			}
			if (this.jobData.jobs !== null) {
				this.jobData.jobs.forEach((job: JobModel) => {
					job.select_value = moment(job.date_start, 'YYYY-MM-DD').format(DATETIMES.DATE_LONG);
					this.startdates.push(job);
				});
			}
			// set form values
			if (this.candidateevent !== null) {
				this.jobForm.patchValue({
					jobtype: (this.candidateevent.jobtype_id !== null) ? this.jobtypes.find(jobtype => jobtype.id === this.candidateevent.jobtype_id) : null, // this.jobTypes[0],
					joblocation: (this.candidateevent.joblocation_id !== null) ? this.joblocations.find(joblocation => joblocation.id === this.candidateevent.joblocation_id) : null,
					date_start: (this.candidateevent.job_id !== null) ? this.startdates.find(startdate => startdate.id === this.candidateevent.job_id) : null,
				});
			}
		});
		// form events
		this.jobForm.get('jobtype').valueChanges.subscribe(jobtype => {
			if (jobtype !== null) {
				const joblocation_ids = this.startdates.map(startdate => (startdate.jobtype_id === jobtype?.id) ? startdate.joblocation_id : null);
				this.filteredJobLocations = this.joblocations.filter(joblocation => joblocation_ids.indexOf(joblocation.id) !== -1);
			} else {
				this.filteredJobLocations = [];
				this.filteredStartDates = [];
			}
			if (this.activeJobtype !== jobtype) {
				this.setActiveLocation(null);
			}
			this.activeJobtype = jobtype || null;
      this.setCitizenship();
      this.emitData();
		});
		this.jobForm.get('joblocation').valueChanges.subscribe(joblocation => {
			if (joblocation !== null && this.activeJobtype !== null) {
				const job_ids = this.startdates.map(startdate => (startdate.jobtype_id === this.activeJobtype.id && startdate.joblocation_id === joblocation.id) ? startdate.id : null);
				this.filteredStartDates = this.startdates.filter(startdate => job_ids.indexOf(startdate.id) !== -1);
			} else {
				this.filteredStartDates = [];
			}
			if (this.activeJoblocation !== joblocation) {
				this.setActiveStartDate(null);
			}
			this.activeJoblocation = joblocation;
		});
		this.jobForm.get('date_start').valueChanges.subscribe(startdate => {
			this.activeStartdate = startdate;
      // this.setCitizenship();
      this.emitData();
		});
		this.jobForm.get('citizenship').valueChanges.subscribe(() => {
      this.emitData();
		});
		if (this.showValidation === true) {
			this.fh.validateAllFormFields(this.jobForm);
		}
	}

	setActiveJobtype(jobtype: JobtypeModel) {
		this.jobForm.patchValue({
			jobtype: jobtype
		});
	}

	setActiveLocation(joblocation: JoblocationModel) {
		this.jobForm.patchValue({
			joblocation: joblocation
		});
	}

	setActiveStartDate(start_date: any) {
		this.jobForm.patchValue({
			date_start: start_date
		});
	}

	saveJob() {
		if (this.jobForm.valid === true || this.candidateevent.slot === null) {
			this.set.emit({
				event: this.event.id,
				jobtype: (this.activeJobtype !== null) ? this.activeJobtype.id : null,
				joblocation: (this.activeJoblocation !== null) ? this.activeJoblocation.id : null,
				date_start: (this.activeStartdate !== null) ? this.activeStartdate.id : null
			});
		} else {
			this.fh.validateAllFormFields(this.jobForm);
		}
	}

	cancel() {
		this.cancelled.emit(null);
	}

  private setCitizenship(): void {
    if (this.activeJobtype?.misc?.mod === true) {
      this.hasCitizenship = true;
      this.jobForm.get('citizenship').setValidators([Validators.required]);
    } else {
      this.hasCitizenship = false;
      this.jobForm.get('citizenship').clearValidators();
    }
    this.jobForm.get('citizenship').updateValueAndValidity();
    this.jobForm.updateValueAndValidity();
  }

  private emitData(): void {
    setTimeout(() => {
      this.changed.emit({
        jobtype: (this.activeJobtype !== null) ? this.activeJobtype.id : null,
        joblocation: (this.activeJoblocation !== null) ? this.activeJoblocation.id : null,
        date_start: (this.activeStartdate !== null) ? this.activeStartdate.id : null,
        citizenship: this.jobForm.get('citizenship').value?.id || null,
        valid: this.jobForm.valid
      });
    }, 0);
  }

}
