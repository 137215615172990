import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { EventModel } from '../../../../../../../sb-lib/src/lib/models/event.model';

@Component({
	selector: 'app-job-skills',
	templateUrl: './job-skills.component.html',
	styleUrls: ['./job-skills.component.scss']
})
export class JobSkillsComponent implements OnInit {

	@Input() event: EventModel;
	@Input() data: any;

	@Output() dataChange = new EventEmitter();
	@Output() registerClicked = new EventEmitter();

	jobtypeId: string = null;
	jobValid = false;
	skillsValid = false;

	constructor(
	  public transloco: TranslocoService
  ) { }

	ngOnInit() {
	}

	back() {
		this.data.step = (this.event.is_searay === true) ? 1 : 2;
		this.dataChange.emit(this.data);
	}

	register() {
		if (this.skillsValid === true) {
			this.registerClicked.emit();
		}
	}

	jobChanged(data: any) {
		this.jobValid = data.valid;
		this.data.candidate.job = {
			jobtype: data.jobtype,
			joblocation: data.joblocation,
			date_start: data.date_start,
		};
    this.data.candidate.citizenship = data.citizenship;
		setTimeout(() => {
			this.jobtypeId = data.jobtype;
			this.dataChange.emit(this.data);
		}, 100);
	}

	skillsChanged(data: any) {
		this.skillsValid = data.valid;
		this.data.candidate.skills = data.skills;
		this.data.candidate.languages = data.languages;
		this.dataChange.emit(this.data);
	}

}
