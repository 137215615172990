<ng-container *transloco="let t;">
<div class="uk-width-1-1" *ngIf="loading === true">
	<p><span uk-spinner></span>&nbsp;&nbsp;&nbsp;{{ t('partials.jobselector.loading_job_data') }}</p>
</div>

<div *ngIf="loading === false">
	<p>{{ t('partials.jobselector.specify_position') }}:</p>
	<form>
		<div uk-grid class="uk-child-width-1-3@m">
			<div>
				<sb-forms-select [control]="jobForm.get('jobtype')" [label]="t('partials.jobselector.jobtype')" [options]="jobtypes"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="jobForm.get('joblocation')" [label]="t('partials.jobselector.joblocation')" [options]="filteredJobLocations"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="jobForm.get('date_start')" [label]="t('partials.jobselector.start_date')" [options]="filteredStartDates"></sb-forms-select>
			</div>
			<div *ngIf="hasCitizenship === true">
        <p>{{ t('partials.jobselector.specify_citizenship') }}:</p>
				<sb-forms-select [control]="jobForm.get('citizenship')" [label]="t('partials.jobselector.citizenship')" [options]="countries"></sb-forms-select>
			</div>
		</div>
		<p class="uk-text-right" *ngIf="mode === 'my-event'">
			<button type="button" class="uk-button kn-button" (click)="cancel()">{{ t('common.cancel') }}</button>&nbsp;<button type="submit" class="uk-button kn-call-to-action" (click)="saveJob()">{{ t('common.save') }}</button>
		</p>
	</form>

</div>

</ng-container>
