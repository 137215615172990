<ng-container *transloco="let t;">
  <div class="uk-container">

    <div class="uk-section">
      <h1>{{ t('profile.myprofile.my_profile') }}</h1>

      <app-loader [mode]="loadingMode" [messageLoading]="t('profile.myprofile.loading_profile')" [messageError]="t('profile.myprofile.profile_not_loaded')"></app-loader>

      <div *ngIf="loadingMode === null">
        <div class="uk-margin-bottom profile-status">
          <div uk-grid>
            <div class="uk-width-2-3">
              <h2>
                <img [src]="avatarUrl">
                {{candidate.firstname}} {{candidate.lastname}}
              </h2>
            </div>
            <div class="uk-width-1-3 uk-text-right">
              <sb-ui-circular [current]="candidate.getProfileStatus()" [radius]="35" [text]="candidate.getProfileStatus() + '%'"></sb-ui-circular>
            </div>
          </div>
        </div>

        <p *ngIf="avatarMode !== true"><a (click)="setAvatarMode()"><fa-icon [icon]="faUserCircle"></fa-icon> {{ t('profile.myprofile.change_profile_picture') }}</a></p>
        <div *ngIf="avatarMode === true" uk-grid>
          <div class="uk-width-1-3@m uk-margin">
            <div uk-form-custom class="uk-width-1-1 uk-margin file-selector">
              <input type="file" ng2FileSelect [uploader]="uploader" (change)="onSelectAvatarImageFile($event)" />
              <span class="uk-link">{{ t('profile.myprofile.click_select_image') }}</span>
            </div>
            <div uk-grid class="uk-child-width-1-2">
              <div>
                <button type="button" class="uk-button kn-button uk-width-1-1"
                  (click)="clearAvatarMode(true)">
                  {{ t('common.cancel') }}
                </button>
              </div>
              <div>
                <button type="button" class="uk-button kn-call-to-action uk-width-1-1"
                  (click)="uploadAvatarImage()" [disabled]="!uploader.getNotUploadedItems().length">
                  {{ t('common.upload') }}
                </button>
              </div>
            </div>
            <progress class="uk-progress" [value]="uploader.progress" max="100"></progress>
          </div>
        </div>

        <div uk-alert class="uk-alert-danger" *ngIf="candidate.getProfileStatus() &gt;= 99 && candidate.events_status === false">
          <span uk-icon="icon: warning"></span> {{ t('profile.myprofile.to_schedule_interview') }}, <a [routerLink]="['/profile/my-events']">{{ t('profile.myprofile.fill_event_parameters') }}</a> !
        </div>

        <form class="uk-form-stacked" [formGroup]="dataForm" novalidate>

          <div class="uk-card uk-card-default uk-margin-bottom" [ngClass]="{'show-complete-info': toComplete.indexOf('personal') !== -1 && editMode === null}">
            <div class="uk-card-body">
              <h3>{{ t('profile.myprofile.personal_informations') }}</h3>
              <app-messagelist [group]="'personal'"></app-messagelist>
              <div uk-grid>
                <div class="uk-width-1-4@m">
                  <sb-forms-input [control]="dataForm.get('personal.firstname')" [label]="t('common.firstname')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-input>
                  <sb-forms-input *ngIf="candidate.hasProperty('SEARAY_ONLY')" [control]="dataForm.get('common.middlename')" [label]="t('common.middlename')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-input>
                  <sb-forms-input [control]="dataForm.get('personal.lastname')" [label]="t('common.lastname')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-input>
                </div>
                <div class="uk-width-1-2@m">
                  <sb-forms-input [control]="dataForm.get('personal.address_street')" [label]="t('common.address')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-input>
                  <sb-forms-zipcity
                    [zipControl]="dataForm.get('personal.address_zip')"
                    [cityControl]="dataForm.get('personal.address_city')"
                    [stateControl]="dataForm.get('personal.address_state')"
                    [readonly]="editMode !== 'personal'"
                    (editClicked)="editPersonalData()"
                  ></sb-forms-zipcity>
                </div>
                <div class="uk-width-1-4@m">
                  <sb-forms-phone [control]="dataForm.get('personal.mobile')" [label]="t('common.mobile')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-phone>
                  <sb-forms-input [control]="dataForm.get('personal.email')" [label]="t('common.email')" [readonly]="editMode !== 'personal'" (editClicked)="editPersonalData()"></sb-forms-input>
                </div>
              </div>
            </div>
            <div *ngIf="editMode === 'personal'" class="uk-card-footer uk-text-right">
              <button type="button" class="uk-button kn-button" (click)="cancelForm()">{{ t('common.cancel') }}</button>&nbsp;<button type="submit" class="uk-button kn-call-to-action" (click)="savePersonalData()" [disabled]="!dataForm.get('personal').valid">{{ t('common.save') }}</button>
            </div>
            <div *ngIf="editMode !== 'personal' && candidate?.can_edit_profile === true" class="uk-card-footer">
              <a (click)="editPersonalData()" class="sb-function"><span uk-icon="icon: pencil"></span> {{ t('common.edit') }}</a> <span class="complete-info"><span uk-icon="icon: arrow-left"></span> {{ t('profile.myprofile.click_fill_details') }}</span>
            </div>
          </div>

          <div *ngIf="!candidate.hasProperty('SEARAY_ONLY')" class="uk-card uk-card-default uk-margin-bottom" [ngClass]="{'show-complete-info': toComplete.indexOf('career') !== -1 && editMode === null}">
            <div class="uk-card-body">
              <h3>{{ t('profile.myprofile.career') }}</h3>
              <app-messagelist [group]="'career'"></app-messagelist>
              <div *ngIf="editMode !== 'career'" uk-grid class="uk-child-width-1-3@m">
                <div>
                  <p>{{ t('profile.myprofile.knbefore') }} <strong *ngIf="dataForm.get('knbefore').value === true">{{ t('sblib.common.yes') }}</strong><strong *ngIf="dataForm.get('knbefore').value !== true">{{ t('sblib.common.no') }}</strong></p>
                </div>
                <div>
                  <p *ngIf="dataForm.get('nocareer').value === true">{{ t('profile.myprofile.no_prior_work') }}</p>
                  <p *ngIf="dataForm.get('nocareer').value === false && dataForm.get('career')['controls'].length === 0">{{ t('profile.myprofile.no_work_history_available') }}</p>
                </div>
              </div>
              <div *ngIf="editMode === 'career'" uk-grid class="uk-child-width-1-3@m">
                <div><sb-forms-checkbox [control]="dataForm.get('knbefore')" [label]="t('profile.myprofile.knbefore')" [readonly]="editMode !== 'career'" (changed)="onKnBeforeChanged($event)"></sb-forms-checkbox></div>
                <div><sb-forms-checkbox *ngIf="dataForm.get('knbefore').value === false" [control]="dataForm.get('nocareer')" [label]="t('profile.myprofile.no_prior_work')" [readonly]="editMode !== 'career'" [hidevalue]="true" (changed)="onNoCareerChanged($event)"></sb-forms-checkbox></div>
              </div>
              <div *ngIf="dataForm.get('nocareer').value === false" class="uk-margin-top">
                <hr *ngIf="editMode === 'career'">
                <div uk-grid class="uk-child-width-1-3@m">
                  <div formArrayName="career" *ngFor="let entry of dataForm.get('career')['controls']; let i = index;">
                  <p>{{ t('profile.myprofile.job') }} {{i+1}} <a *ngIf="editMode === 'career' && dataForm.get('career')['controls'].length > 1" (click)="deleteCareerEntry(i)">{{ t('common.remove') }}</a>:</p>
                  <div [formGroupName]="i">
                    <div>
                        <sb-forms-input [control]="entry.get('employer')" [label]="t('profile.myprofile.company')" [readonly]="editMode !== 'career'" (editClicked)="editCareerData()"></sb-forms-input>
                    </div>
                    <div>
                        <sb-forms-input [control]="entry.get('occupation')" [label]="t('profile.myprofile.title_position')" [readonly]="editMode !== 'career'" (editClicked)="editCareerData()"></sb-forms-input>
                    </div>
                    <div>
                    <div uk-grid class="uk-grid-small uk-child-width-1-2">
                      <div>
                          <sb-forms-datepicker [control]="entry.get('from')" [label]="t('common.from')" [readonly]="editMode !== 'career'"  [min]="min" [max]="max" (editClicked)="editCareerData()"></sb-forms-datepicker>
                      </div>
                      <div>
                          <sb-forms-datepicker [control]="entry.get('to')" [label]="t('common.to')" [readonly]="editMode !== 'career'"  [min]="min" [max]="max" [showPresentDay]="true" (editClicked)="editCareerData()"></sb-forms-datepicker>
                      </div>
                    </div>
                    </div>
                  </div>
                  </div>
                  <div *ngIf="editMode === 'career' && dataForm.get('career')['controls'].length &lt; 3">
                  <p><a (click)="addCareerEntry()">{{ t('profile.myprofile.add_work_experience') }}</a></p>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="editMode === 'career'" class="uk-card-footer uk-text-right">
              <button type="button" class="uk-button kn-button" (click)="cancelForm()">{{ t('common.cancel') }}</button>&nbsp;<button type="submit" class="uk-button kn-call-to-action" (click)="saveCareerData()" [disabled]="!dataForm.get('career').valid">{{ t('common.save') }}</button>
            </div>
            <div *ngIf="editMode !== 'career'" class="uk-card-footer">
              <a (click)="editCareerData()" class="sb-function"><span uk-icon="icon: pencil"></span> {{ t('common.edit') }}</a> <span class="complete-info"><span uk-icon="icon: arrow-left"></span> {{ t('profile.myprofile.click_fill_details') }}</span>
            </div>
          </div>

          <div class="uk-card uk-card-default" *ngIf="candidate.privacypolicy_id !== null">
            <div class="uk-card-body">
              <h3>{{ t('profile.myprofile.privacypolicy') }}</h3>
              <p>{{ t('profile.myprofile.consent_to') }} <a [href]="'/downloads/privacypolicy?email=' + candidate.email" target="_blank">{{ t('profile.myprofile.kn_privacypolicy') }}</a> {{ t('profile.myprofile.was_obtained_on') }} {{ candidate.privacypolicy_at | sbDate: 'DATE_LONG' }}.</p>
            </div>
          </div>

          <p class="uk-margin-top uk-margin-bottom">
            <app-messagelist [group]="'delete_profile'"></app-messagelist>
            <a (click)="onRequestDeleteProfile()"><fa-icon [icon]="faTrash"></fa-icon> {{ t('profile.myprofile.delete_profile') }}</a>
          </p>

        </form>

      </div>

    </div>

  </div>

  <app-collect-eventdata (collected)="onCollected($event)" #datacollector></app-collect-eventdata>

	<div id="deleteModal" uk-modal>
		<div class="uk-modal-dialog">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div class="uk-modal-header">
				<h2 class="uk-modal-title">{{ t('profile.myprofile.delete_profile') }}</h2>
			</div>
			<div class="uk-modal-body">
				{{ t('profile.myprofile.really_delete_profile') }}
			</div>
			<div class="uk-modal-footer uk-text-right">
				<button class="uk-button kn-button uk-modal-close">{{ t('sblib.common.no') }}</button>&nbsp;<button class="uk-button kn-call-to-action" (click)="onDeleteProfile()">{{ t('sblib.common.yes') }}</button>
			</div>
		</div>
	</div>

</ng-container>
