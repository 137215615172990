// @i18n

export const EVENTCODE_TLD = '@kncandidateday.co.uk';
export const COUNTRY_NAME = 'United Kingdom';
export const COUNTRY_SHORTCUT = 'UK';
export const COUNTRY_SHORTCUT_PHONENUMBER = 'GB';

export const MAX_EVENT_LENGTH = 14;

export const DATETIMES: any = {
  DATE_SHORT: 'DD MMM',
  DATE_LONG: 'DD MMM YYYY',
  DATE_MONTH_YEAR: 'MMM YYYY',
  DATE_REVERSE: 'YYYY-MM-DD',
  TIME_SHORT: 'hh:mm A',
  TIME_LONG: 'hh:mm:ss A',
  DATETIME_SHORT: 'DD MMM, hh:mm A',
  DATETIME_LONG: 'DD MMM YYYY, hh:mm A',
  DATETIME_FULL_REVERSE: 'YYYY-MM-DD HH:mm:ss',
  DATE_PRIMEPICKER_LONG: 'dd M yy',
  DATE_PRIMEPICKER_MONTH_YEAR: 'y',
  HOUR_FORMAT: 12,
  FIRST_DAY_OF_WEEK: 1
}

export const USER_ROLES: any = {
	administrator: 'Administrator',
	genius: 'Genius',
	recruiter: 'Recruiter',
	'registration-clerk': 'Registration Clerk',
	'back-office': 'Back Office',
	management: 'Management',
};

export const META_ROLES: any = {
	user: 'User',
	admin: 'Administrator',
	superuser: 'Super User'
};

export const CURRENCIES_SHORT: any = {
	USD: '$',
	GBP: '£',
	EUR: '€'
}

export const EVENT_TYPES: any = {
	'candidate-friday': 'CANDIDATEfriday',
	// 'candidate-day': 'CANDIDATEday',
	// 'searay': 'SeaRay'
	// 'takeover-blue': 'TAKEOVERblue'
};

export const EVENT_STATUSES: any = {
	setup: 'Set-up',
	standby: 'Standby',
	online: 'Published',
	running: 'Running',
	finished: 'Finished',
	archived: 'Archived',
};

export const EVENTDAY_STATUSES: any = {
	setup: 'Set-up',
	standby: 'Standby',
	running: 'Running',
	finished: 'Finished',
	archived: 'Archived',
};

export const CANDIDATEEVENT_STATUSES: any = {
	registered: 'Registered',
	noshow: 'No Show',
	interview: 'Interview',
	finished: 'Finished',
};

export const CANDIDATEEVENT_INTERVIEW_STATUSES: any = {
	hired: 'Hired',
	pooled: 'Pooled',
	rejected: 'Rejected',
	declined: 'Declined',
};

export const CANDIDATEEVENT_LISTSTATUSES: any = {
	registered: 'Registered',
	ready: 'Ready',
	noshow: 'No Show',
	interview: 'Interview',
	finished: 'Finished',
};

export const REASONS: any = {
	pooled: [
		'Job Location and Start date does not fit for candidate',
		'Recruiter ideal job profile is not available',
		'Candidate ideal job profile is not available',
		'Salary requirements of the candidate are too high'
	],
	rejected: [
		'Does not meet general minimum requirements',
		'Does not meet minimum qualifications - location',
		'Does not meet minimum qualifications - amount of experience',
		'Does not meet minimum qualifications - relevant experience',
		'Does not meet minimum qualifications - Right to Work in the UK',
		'Salary requirements too high'
	],
	declined: [
		'I prefer not to answer',
		'Personal or Family reasons ',
		'Salary requirements are too low',
		'Benefits are not attractive',
		'Schedule does not work – working hours, shift etc.',
		'Prefer staying with the current employer',
		'Travel distance is too far',
		'Job Location is not appealing ',
	],
};

export const WORKFORCE: any = {
	'Blue collar': 'Blue collar',
	'White collar': 'White collar',
};

export const WORKFORCE_LEVEL: any = {
	1: 1,
	2: 2,
	3: 3,
};

export const PAY_GROUP: any = {
	'Semi-monthly': 'Semi-monthly',
	'Weekly': 'Weekly',
};

export const ACTION_REASON: any = {
	'New Hire (NHR)': 'New Hire (NHR)',
	'Rehire (REH)': 'Rehire (REH)',
	'Transfer from Affiliate (HAF)': 'Transfer from Affiliate (HAF)',
};

export const JOBTYPE_STATUS: any = {
	employee: 'employee',
	// 'middle management': 'middle management',
	// executive: 'executive'
};

export const REQ_EMPLOYMENT_TYPE: any = {
	Permanent: 'Permanent',
	Temporary: 'Temporary',
};

export const REQ_FULLPART_TIME: any = {
	'Full Time': 'Full Time',
	'Part Time': 'Part Time',
};

export const SKILL_NAMES: any = {
	1: 'Demonstrating Drive + Commitment',
	2: 'Fostering Collaboration + Teamwork',
	3: 'Driving Business + Solutions',
	4: 'Championing Transformation + Innovation',
	5: 'Developing Self + Others',
	6: 'Delivering Customer Excellence',
};

export const SKILL_TYPES: any = {
	yes_no: 'Yes/No',
	text: 'Textfield',
	select: 'Single Select',
	multiple: 'Multiple Select',
};

