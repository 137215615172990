<div id="eventModal" class="uk-modal-container" uk-modal>
  <ng-container *transloco="let t;">
		<div id="modalContainer" class="uk-modal-dialog" *ngIf="event !== null">
			<button class="uk-modal-close-default" type="button" uk-close></button>

			<div class="uk-modal-header">
				<h2 class="uk-modal-title">{{event.name}}</h2>
				<ul uk-tab>
					<li [ngClass]="{'uk-active': activeTab === 'overview'}"><a (click)="setTab('overview');">{{ t('partials.eventdetail.overview') }}</a></li>
					<li *ngIf="event.customer !== null" [ngClass]="{'uk-active': activeTab === 'customer'}"><a (click)="setTab('customer');">{{ t('partials.eventdetail.aboutus') }}</a></li>
					<li [ngClass]="{'uk-active': activeTab === 'jobs'}"><a (click)="setTab('jobs');">{{ t('partials.eventdetail.open_positions') }}</a></li>
					<li [ngClass]="{'uk-active': activeTab === 'locations'}"><a (click)="setTab('locations');">{{ t('partials.eventdetail.joblocations') }}</a></li>
					<li *ngIf="!isLoggedIn" [ngClass]="{'uk-active': activeTab === 'registration'}"><a (click)="setTab('registration');">{{ t('partials.eventdetail.self_registration') }}</a></li>
				</ul>
			</div>


			<div *ngIf="activeTab === 'overview'">
				<div class="uk-modal-body">
					<div uk-grid>
						<div class="uk-width-1-4@m">
							<h3><span uk-icon="icon: calendar; ratio: 1.4"></span> {{ t('partials.eventdetail.date_of_event') }}</h3>
							<p [innerHtml]="event.date_full"></p>
						</div>
						<div class="uk-width-1-4@m" *ngIf="!event.isVirtual()">
							<h3><span uk-icon="icon: location; ratio: 1.4"></span> {{ t('partials.eventdetail.location_of_event') }}</h3>
							<p>{{event.address_name}}<br>{{event.address_street}}<br>{{event.address_city}}<br>{{event.address_state}} {{event.address_zip}}</p>
						</div>
						<div class="uk-width-1-2@m" *ngIf="!event.isVirtual()">
              <google-map
                width="100%"
                height="200px"
                [center]="{lat: event.address_lat, lng: event.address_lon}"
                [options]="mapOptions"
              >
                <map-marker
                  [position]="{lat: event.address_lat, lng: event.address_lon}"
                >
                </map-marker>
              </google-map>
						</div>
					</div>
					<hr>
          <p *ngIf="event.teasertext">{{event.teasertext}}</p>
          <p *ngIf="event.description">{{event.description}}</p>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<button class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</button>
				</div>
			</div>


			<div *ngIf="activeTab === 'jobs'">
				<div class="uk-modal-body">
					<div uk-grid>
						<div class="uk-width-1-4@m">
							<p class="headline">{{ t('partials.eventdetail.open_positions') }}</p>
							<ul class="uk-list list-selector">
								<li *ngFor="let jobtype of event.jobtypes" [ngClass]="{'active': jobtype === jobtypeActive}" (click)="setJobtype(jobtype)">{{jobtype.title}}&nbsp;<span uk-icon="icon: chevron-double-right"></span></li>
							</ul>
						</div>
						<div class="uk-width-3-4@m">
              <div class="job-profile" [innerHTML]="jobtypeActive.content"></div>
						</div>
					</div>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<button class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</button>
				</div>
			</div>


			<div *ngIf="activeTab === 'locations'">
				<div class="uk-modal-body">
					<div uk-grid>
						<div class="uk-width-1-4@m">
							<p class="headline">{{ t('partials.eventdetail.list_of_sites') }}</p>
							<ul class="uk-list list-selector">
								<li *ngFor="let joblocation of event.joblocations" [ngClass]="{'active': joblocation === joblocationActive}" (click)="setJoblocation(joblocation)">{{joblocation.address_city}}&nbsp;<span uk-icon="icon: chevron-double-right"></span></li>
							</ul>
						</div>
						<div class="uk-width-1-4@m">
							<p class="headline">{{ t('partials.eventdetail.adresses_of_sites') }}</p>
							<p>{{joblocationActive.address_street}}<br>{{joblocationActive.address_city}}<br>{{joblocationActive.address_state}} {{joblocationActive.address_zip}}</p>
						</div>
						<div class="uk-width-1-2@m">
              <google-map
                width="100%"
                height="200px"
                [center]="{lat: joblocationActive.address_lat, lng: joblocationActive.address_lon}"
                [options]="mapOptions"
              >
                <map-marker
                  [position]="{lat: joblocationActive.address_lat, lng: joblocationActive.address_lon}"
                >
                </map-marker>
              </google-map>
						</div>
					</div>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<button class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</button>
				</div>
			</div>


			<div *ngIf="event.customer !== null && activeTab === 'customer'">
				<div class="uk-modal-body">
					<div uk-grid [ngClass]="{'uk-child-width-1-2@m': event.customer.image_video !== null}">
						<div>
							<p class="customer-logo uk-text-center"><img [src]="event.customer.logo_image"></p>
							<p [innerHTML]="event.customer.description"></p>
						</div>
						<div *ngIf="event.customer.image_video !== null">
							<video controls playsinline uk-video>
								<source [src]="event.customer.image_video" type="video/mp4">
							</video>
						</div>
					</div>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<button class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</button>
				</div>
			</div>

			<div [hidden]="activeTab !== 'registration'">
				<app-prereg-day *ngIf="event.is_candidate_day === true" [event]="event"></app-prereg-day>
				<app-prereg-friday *ngIf="event.is_candidate_friday === true" [event]="event"></app-prereg-friday>
				<app-prereg-searay *ngIf="event.is_searay === true" [event]="event"></app-prereg-searay>
			</div>

		</div>
  </ng-container>
</div>
