<ng-container *transloco="let t;">
<div class="navbar">
	<div class="uk-container">
		<nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
			<div class="uk-navbar-left uk-visible@m">
				<ul class="uk-navbar-nav">
					<li routerLinkActive="uk-active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/">{{ t('partials.layout.navbar.home') }}</a></li>
					<li routerLinkActive="uk-active"><a routerLink="/aboutus">{{ t('partials.layout.navbar.aboutus') }}</a></li>
					<li routerLinkActive="uk-active"><a routerLink="/search">{{ t('partials.layout.navbar.search') }}</a></li>
					<li routerLinkActive="uk-active"><a routerLink="/meetus">{{ t('partials.layout.navbar.meet_us') }}</a></li>
					<li routerLinkActive="uk-active"><a routerLink="/goodtoknow">{{ t('partials.layout.navbar.goodtoknow') }}</a></li>
				</ul>
			</div>
			<div class="uk-navbar-right uk-visible@m">
				<app-login></app-login>
			</div>
			<div class="uk-navbar-right uk-hidden@m">
				<ul class="uk-navbar-nav">
					<li><a href="#offCanvasNav" uk-toggle>{{ t('partials.layout.navbar.menu') }}&nbsp;<span uk-icon="icon: menu"></span></a></li>
				</ul>
			</div>
		</nav>
	</div>
</div>

</ng-container>
